.side {
  width: 8px !important;
  height: 8px !important;
  background-color: #b4b4b4;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 5px;
  transition: all 0.5s;
}
.side.active {
  width: 24px !important;
  background-color: #fff;
}
.swipe-icons {
  display: grid;
  place-items: center;
  position: relative;
  margin-left: 4px;
}
.swipe-icons .first {
  position: absolute;
  top: 0;
  left: -5px;
  transform: scale(0.8);
}
