@font-face {
  font-family: "SF Pro Rounded";
  src: url("./assets/Fonts/SF-Pro-Rounded-Ultralight.otf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Rounded";
  src: url("./assets/Fonts/SF-Pro-Rounded-Thin.otf") format("truetype");
  /* src: url("./assets/Fonts/SF-Pro-Rounded-Light.otf") format("truetype"); */
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Rounded";
  src: url("./assets/Fonts/SF-Pro-Rounded-Light.otf") format("truetype");
  /* src: url("./assets/Fonts/SF-Pro-Rounded-Thin.otf") format("truetype"); */
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Rounded";
  src: url("./assets/Fonts/SF-Pro-Rounded-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Rounded";
  src: url("./assets/Fonts/SF-Pro-Rounded-Medium.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Rounded";
  src: url("./assets/Fonts/SF-Pro-Rounded-Semibold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Rounded";
  src: url("./assets/Fonts/SF-Pro-Rounded-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Rounded";
  src: url("./assets/Fonts/SF-Pro-Rounded-Black.otf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
* {
  font-family: SF Pro Rounded, Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: 0.6px !important;
}
#get-started-footer:hover {
  background-color: rgb(235, 235, 235);
}
.MuiInputBase-root {
  border-radius: 10px !important;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.truncate-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.truncate-2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.special-label {
  display: none !important;
}
.react-tel-input .form-control {
  border-radius: 10px !important;
  box-shadow: none !important;
}
.react-tel-input .form-control:focus {
  border: 1px solid #092c48 !important;
}
.cursor-pointer {
  cursor: pointer;
}
